import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useHistory } from "react-router-dom";
import "./index.css";

type Props = {
  children: JSX.Element | JSX.Element[] | string;
};

export function ModalPage({ children }: Props) {
  const history = useHistory();

  const toggle = () => {
    history.push("/");
  };

  return (
    <Modal isOpen size="lg" className="ModalPage__container">
      <ModalHeader toggle={toggle} />
      <ModalBody className="p-md-5 p-sm-3">{children}</ModalBody>
    </Modal>
  );
}
