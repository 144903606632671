export let currentHostURI = "https://wellnesschallenge.pvh.com";

const origin = window.location.origin;
if (origin.indexOf("localhost") >= 0) {
  currentHostURI = "http://localhost:3000";
} else if (origin.indexOf("eu.pvh.cloud") >= 0) {
  currentHostURI = origin;
}

export const azureOAuth2Configuration = {
  authority:
    "https://login.microsoftonline.com/50ccd526-f6e9-4d12-8d35-391b59f63cd6",
  clientId: "0cb48410-29a3-4bfe-9e9b-bd2281718baf",
  redirectUri: `${currentHostURI}/auth`,
};
