import React from "react";
import { Link } from "react-router-dom";

export function WellnessChallengeAbout() {
  return (
    <div className="WellnessChallengeAbout__container">
      <div className="WellnessChallenge__modal_before_title">
        PVH Europe 21 Day Wellness Challenge
      </div>
      <div className="WellnessChallenge__modal_title">
        Our Donation, Your Choice
      </div>
      <p>
        After an incredible three weeks, the 21-Day Wellness Challenge is over.{" "}
        We kickstarted the year with fun, new and healthy habits, collectively
        raising an incredible €250,000 to donate to impactful causes globally.
      </p>
      <p>
        The five charities for which we raised money are working extremely hard
        globally to combat some of the biggest issues facing our society and our
        planet. And now you can decide how we distribute our donation to these
        deserving causes. Cast your vote for your preferred charity. The more
        votes each receives, the bigger percentage of the pot they get.
      </p>
      <ul>
        <li>1st: €75,000</li>
        <li>2nd: €62,500</li>
        <li>3rd: €50,000</li>
        <li>4th: €37,500</li>
        <li>5th: €25,000</li>
      </ul>

      <p>
        Check out our partners and the projects our donations will support on
        the <Link to="/charities">Charity Partners</Link> page.
      </p>

      <p>
        Voting closes Friday, February 5th, 11:59 p.m. CET and results will be
        announced later that month.
      </p>
    </div>
  );
}
