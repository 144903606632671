import { Switch, Route } from "react-router-dom";
import "./index.css";
import featuredImage3 from "./assets/featured3.png";

export function WellnessChallengeFeaturedImage() {
  return (
    <div className="WellnessChallengeFeaturedImage__container h-100">
      <Switch>
        <Route path="/">
          <div
            style={{ backgroundImage: `url(${featuredImage3})` }}
            className="WellnessChallengeFeaturedImage__image"
          />
        </Route>
      </Switch>
    </div>
  );
}
