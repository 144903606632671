import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Switch, Route } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { AccountContext } from "../../App";
import { WellnessChallengeMainContent } from "../../pages/WellnessChallengeMainContent";
import { WellnessChallengeFeaturedImage } from "../../components/WellnessChallengeFeaturedImage";
import { WellnessChallengeAbout } from "../../pages/WellnessChallengeAbout";
import { WellnessChallengeCharities } from "../../pages/WellnessChallengeCharities";
import { ModalPage } from "../../components/ModalPage";
import { UserIcon } from "../../components/icons/UserIcon";
import "./index.css";

export function WellnessChallenge() {
  return (
    <AccountContext.Consumer>
      {(account) => (
        <React.Fragment>
          <div className="Application__container px-0 px-md-4 m-auto h-100">
            <Container fluid>
              <Row className="h-100">
                <Col md={6}>
                  <div className="flex-column d-flex h-100">
                    <Navigation className="mt-3 flex-grow-0" />
                    <div className="Application__main_content flex-grow-1 h-100 d-flex align-items-center">
                      <Switch>
                        <Route path="/">
                          {account && (
                            <WellnessChallengeMainContent
                              account={account}
                              className="p-3"
                            />
                          )}
                        </Route>
                      </Switch>
                    </div>
                    <div className="flex-grow-0">
                      <div className="m-4 p-5" />
                      <div className="m-1 p-1" />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="d-none d-md-block h-100">
                    <WellnessChallengeFeaturedImage />
                    <div className="Application__featured_image_username">
                      <UserIcon /> {account?.account.name || "Welcome"}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <Switch>
            <Route path="/about" exact>
              <ModalPage>
                <WellnessChallengeAbout />
              </ModalPage>
            </Route>
            <Route path="/charities" exact>
              <ModalPage>
                <WellnessChallengeCharities />
              </ModalPage>
            </Route>
          </Switch>
        </React.Fragment>
      )}
    </AccountContext.Consumer>
  );
}
