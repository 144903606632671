import { Alert, Button, Container, Table } from "reactstrap/es";
import { useCallback, useEffect, useState } from "react";
import {
  Activity,
  fetchActivities,
  requestDownloadSignature,
} from "../../../services/api";

type Props = {
  accessToken: string;
};

export function AdminActivities({ accessToken }: Props) {
  const [activities, setActivities] = useState<Activity[]>([]);
  const [error, setError] = useState<string>();

  const fetchActivitiesOnLoad = useCallback(async () => {
    try {
      const data = await fetchActivities(accessToken);
      data.sort((a, b) =>
        (a.createdAt ? a.createdAt : 0) < (b.createdAt ? b.createdAt : 0)
          ? 1
          : -1
      );
      setActivities(data);
    } catch (e) {
      setError(e.message || "Something went wrong");
    }
  }, [accessToken]);

  useEffect(() => {
    fetchActivitiesOnLoad();
  }, [fetchActivitiesOnLoad]);

  const downloadFile = async (key: string) => {
    try {
      const { url } = await requestDownloadSignature(key, accessToken);
      window.open(url);
    } catch (e) {
      alert("Error downloading file");
    }
  };
  return (
    <Container className="pt-5" fluid>
      <h3>Activities</h3>
      <hr />
      {error && (
        <Alert color="danger">Failed to fetch activities: {error}</Alert>
      )}
      {!error && (
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Activity</th>
              <th>Comments</th>
              <th>Link</th>
              <th>Email</th>
              <th>Created at</th>
              <th>Files</th>
            </tr>
          </thead>
          <tbody>
            {activities.map((activity) => (
              <tr>
                <td>{activity.activity}</td>
                <td>{activity.comment}</td>
                <td>
                  {activity.link && (
                    <a href={activity.link} target="_blank" rel="noreferrer">
                      {activity.link.replace("https://", "").slice(0, 30)}...
                    </a>
                  )}
                </td>
                <td>{activity.email}</td>
                <td>{activity.createdAt}</td>
                <td>
                  {activity.files.map((fileKey) => (
                    <div>
                      <Button
                        color="link"
                        onClick={() => downloadFile(fileKey)}
                      >
                        {fileKey}
                      </Button>
                      <hr />
                    </div>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
}
