import { currentHostURI } from "../config";

const baseApiUrl = `${currentHostURI}/api`;

export const requestUploadSignature = async (
  file: File,
  email: string,
  accessToken: string
): Promise<{ url: string; key: string }> => {
  const response = await fetch(`${baseApiUrl}/sign-file-upload`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      filename: file.name,
      contentType: file.type,
      email,
    }),
  });
  if (!response.ok) throw new Error("Error requesting upload URL");
  const { data } = await response.json();
  return data;
};

export const requestDownloadSignature = async (
  key: string,
  accessToken: string
): Promise<{ url: string }> => {
  const response = await fetch(`${baseApiUrl}/sign-get-file`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      key,
    }),
  });
  if (!response.ok) throw new Error("Error requesting download URL");
  const { data } = await response.json();
  return data;
};

export type Activity = {
  id?: string;
  email: string;
  files: string[];
  link?: string;
  activity: string;
  comment?: string;
  createdAt?: string;
};

export const postActivity = async (
  { email, files, link, activity, comment }: Activity,
  accessToken: string
): Promise<void> => {
  const response = await fetch(`${baseApiUrl}/activities`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      email,
      files,
      link,
      activity,
      comment,
    }),
  });
  if (!response.ok || response.status !== 204)
    throw new Error("Error saving activity data");
};

export const fetchActivities = async (
  accessToken: string
): Promise<Activity[]> => {
  const response = await fetch(`${baseApiUrl}/activities`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (!response.ok) throw new Error("Error requesting activities");
  const { data } = await response.json();
  return data;
};

export type Vote = { email: string; organization: string; createdAt?: string };
export type UserVote = {
  userVote?: Vote;
  organizationsPartial?: { [key: string]: number };
  total?: number;
};

export const fetchUserVote = async (accessToken: string): Promise<UserVote> => {
  const response = await fetch(`${baseApiUrl}/votes/me`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (!response.ok) throw new Error("Error requesting user vote");
  const { data } = await response.json();
  return data;
};

export const postVote = async (
  organization: string,
  accessToken: string
): Promise<void> => {
  const response = await fetch(`${baseApiUrl}/votes`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      organization,
    }),
  });
  if (!response.ok || response.status !== 204)
    throw new Error("Error saving voting data");
};

export const fetchVotes = async (accessToken: string): Promise<Vote[]> => {
  const response = await fetch(`${baseApiUrl}/votes`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (!response.ok) throw new Error("Error requesting votes");
  const { data } = await response.json();
  return data;
};
