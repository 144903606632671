import { useState } from "react";
import { Navbar, NavbarToggler, Nav, Collapse, NavbarBrand } from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import logo from "./assets/logo.png";
import "./index.css";

type Props = {
  className?: string;
};

export function Navigation({ className }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar
      className={classnames("Navigation__container", className)}
      light
      expand="md"
    >
      <NavbarBrand className="mr-md-5" tag={Link} to="/">
        <img alt="PVH Cares" src={logo} className="Navigation__logo" />
      </NavbarBrand>
      <NavbarToggler onClick={toggle} className="mr-2" />
      <Collapse isOpen={isOpen} navbar>
        <Nav navbar className="mr-auto">
          <div className="d-flex">
            <div className="mr-5 pt-3">
              <Link to="/about" className="Navigation__link">
                ABOUT
              </Link>
            </div>
            <div className="mr-auto pt-3">
              <Link to="/charities" className="Navigation__link">
                CHARITY PARTNERS
              </Link>
            </div>
          </div>
        </Nav>
      </Collapse>
    </Navbar>
  );
}
