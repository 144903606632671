import { Row, Col } from "reactstrap";
import fma from "./assets/FMA_logo.png";
import ilga from "./assets/ILGA_logo.png";
import redCross from "./assets/RedCross_logo.jpg";
import saveTheChildren from "./assets/SaveTheChildren_logo.jpg";
import wwf from "./assets/WWF_logo.png";

import "./index.css";

export function WellnessChallengeCharities() {
  return (
    <div className="WellnessChallengeCharities__container">
      <div className="WellnessChallenge__modal_before_title">
        PVH Europe 21 Day Wellness Challenge
      </div>
      <div className="WellnessChallenge__modal_title">
        Our Partner Charities
      </div>
      <ul className="WellnessChallengeCharities__organizations_list mt-2">
        <li>
          <Row>
            <Col xs={3} sm={2}>
              <img className="w-100" alt="organization logo" src={wwf} />
            </Col>
            <Col xs={9} sm={10}>
              <div className="WellnessChallengeCharities__organization_name">
                WWF
              </div>
              <div className="WellnessChallengeCharities__organization_description">
                Our planet is facing an emergency; the loss of nature threatens
                everyone’s future. <br />
                The WWF is working together with stakeholders to reduce water,
                chemicals and energy use in production in industry and in
                agriculture in the Turkish Büyük Menderes basin in order to
                contribute to the protection of these freshwater habitats as
                well as species and their populations
              </div>
            </Col>
          </Row>
        </li>
        <li>
          <Row>
            <Col xs={3} sm={2}>
              <img
                className="w-100"
                alt="organization logo"
                src={saveTheChildren}
              />
            </Col>
            <Col xs={9} sm={10}>
              <div className="WellnessChallengeCharities__organization_name">
                Save the children
              </div>
              <div className="WellnessChallengeCharities__organization_description">
                Supporting minor girls in the rural areas of Ethiopia, as they
                work towards equal rights. <br />
                Awareness trainings on education, development and family
                planning will support their mission. <br />
                This project will improve awareness for girls on reproductive
                health and ownership of their bodies and the consequences of
                population pressure and on how it hampers their resilience as it
                exacerbates land scarcity, production needs, unemployment and
                basic social services demand.
              </div>
            </Col>
          </Row>
        </li>
        <li>
          <Row>
            <Col xs={3} sm={2}>
              <img className="w-100" alt="organization logo" src={redCross} />
            </Col>
            <Col xs={9} sm={10}>
              <div className="WellnessChallengeCharities__organization_name">
                Red Cross
              </div>
              <div className="WellnessChallengeCharities__organization_description">
                Red Cross volunteers provide tangible relief to the most
                vulnerable people in Europe impacted by COVID-19. <br />
                Think of medical support, food aid, psycho-social care in
                hospitals and caring homes.
              </div>
            </Col>
          </Row>
        </li>
        <li>
          <Row>
            <Col xs={3} sm={2}>
              <img className="w-100" alt="organization logo" src={fma} />
            </Col>
            <Col xs={9} sm={10}>
              <div className="WellnessChallengeCharities__organization_name">
                Fashion Minority Alliance
              </div>
              <div className="WellnessChallengeCharities__organization_description">
                The Fashion Minority Alliance is an (NGO) non-partisan special
                interest group with the objective to tactically work with
                fashion- and industry- stakeholders to build and foster a more
                diverse, balanced, and inclusive industry that advances
                meaningful and long-term equity for Black and minority talent.
              </div>
            </Col>
          </Row>
        </li>
        <li>
          <Row>
            <Col xs={3} sm={2}>
              <img className="w-100" alt="organization logo" src={ilga} />
            </Col>
            <Col xs={9} sm={10}>
              <div className="WellnessChallengeCharities__organization_name">
                ILGA
              </div>
              <div className="WellnessChallengeCharities__organization_description">
                (International Lesbian, Gay, Bisexual, Trans and Intersex
                Association) In particular supporting the Gender Identity and
                Gender Expression Program that works closely with the UN and
                international organizations program to advance the rights of
                trans people by strengthening work at the global and regional
                levels.
              </div>
            </Col>
          </Row>
        </li>
      </ul>
    </div>
  );
}
