import { Alert, Container, Table } from "reactstrap/es";
import { useCallback, useEffect, useState } from "react";
import { fetchVotes, Vote } from "../../../services/api";

type Props = {
  accessToken: string;
};

type ReducedVotes = { [organization: string]: number };
export function AdminVotes({ accessToken }: Props) {
  const [votes, setVotes] = useState<Vote[]>([]);
  const [organizationVotes, setOrganizationVotes] = useState<ReducedVotes>({});
  const [total, setTotal] = useState<number>();
  const [error, setError] = useState<string>();

  const fetchVotesOnLoad = useCallback(async () => {
    try {
      const data = await fetchVotes(accessToken);
      data.sort((a, b) =>
        (a.createdAt ? a.createdAt : 0) < (b.createdAt ? b.createdAt : 0)
          ? 1
          : -1
      );
      setVotes(data);
      const organizationVotes = data.reduce(
        (acc: ReducedVotes, item) => ({
          ...acc,
          [item.organization]: (acc[item.organization] || 0) + 1,
        }),
        {}
      );
      setOrganizationVotes(organizationVotes);
      const total = Object.keys(organizationVotes).reduce(
        (acc, key) => acc + organizationVotes[key],
        0
      );
      setTotal(total);
    } catch (e) {
      setError(e.message || "Something went wrong");
    }
  }, [accessToken]);

  useEffect(() => {
    fetchVotesOnLoad();
  }, [fetchVotesOnLoad]);

  return (
    <Container className="pt-5" fluid>
      <h3>Votes</h3>
      <hr />
      {error && (
        <Alert color="danger">Failed to fetch activities: {error}</Alert>
      )}
      {!error && (
        <div>
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>Organization</th>
                <th>Votes</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(organizationVotes).map((key) => (
                <tr>
                  <td>{key}</td>
                  <td>{organizationVotes[key]}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td>Total</td>
                <td>{total}</td>
              </tr>
            </tfoot>
          </Table>

          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>Email</th>
                <th>Organization</th>
                <th>Created at</th>
              </tr>
            </thead>
            <tbody>
              {votes.map((activity) => (
                <tr>
                  <td>{activity.email}</td>
                  <td>{activity.organization}</td>
                  <td>{activity.createdAt}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </Container>
  );
}
