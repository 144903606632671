import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { Configuration } from "msal";
import { currentHostURI, azureOAuth2Configuration } from "./config";

// Msal Configurations
const config: Configuration = {
  auth: {
    authority: azureOAuth2Configuration.authority,
    clientId: azureOAuth2Configuration.clientId,
    redirectUri: azureOAuth2Configuration.redirectUri,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

// Authentication Parameters
const authenticationParameters = {
  scopes: ["user.read", "openid", "profile"],
};

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: `${currentHostURI}/auth`,
};

export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
);
