import React from "react";
import {
  AzureAD,
  AuthenticationState,
  IAzureADFunctionProps,
} from "react-aad-msal";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { authProvider } from "./AuthProvider";
import { IAccountInfo } from "react-aad-msal/dist/typings/interfaces";
import { WellnessChallenge } from "./layouts/WellnessChallenge";
import { AdminActivities } from "./pages/Admin/Activities";
import { AdminVotes } from "./pages/Admin/Votes";

export const AccountContext = React.createContext<IAccountInfo | null>(null);

function App() {
  return (
    <AzureAD provider={authProvider} forceLogin={true}>
      {({
        login,
        authenticationState,
        error,
        accountInfo,
      }: IAzureADFunctionProps) => {
        switch (authenticationState) {
          case AuthenticationState.Authenticated:
            return (
              <AccountContext.Provider value={accountInfo}>
                <BrowserRouter>
                  <Switch>
                    <Route path="/admin/activities" exact>
                      {accountInfo && (
                        <AdminActivities accessToken={accountInfo.jwtIdToken} />
                      )}
                    </Route>
                    <Route path="/admin/votes" exact>
                      {accountInfo && (
                        <AdminVotes accessToken={accountInfo.jwtIdToken} />
                      )}
                    </Route>
                    <Route path="/">
                      <WellnessChallenge />
                    </Route>
                  </Switch>
                </BrowserRouter>
              </AccountContext.Provider>
            );
          case AuthenticationState.Unauthenticated:
            return (
              <div>
                {error && (
                  <p>
                    <span>
                      An error occurred during authentication, please try again!
                    </span>
                  </p>
                )}
                <p>
                  <span>Hey, you look new!</span>
                  <button onClick={login}>Login</button>
                </p>
              </div>
            );
          case AuthenticationState.InProgress:
            return <p>Authenticating...</p>;
        }
      }}
    </AzureAD>
  );
}

export default App;
