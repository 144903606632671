import React, { useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import { IAccountInfo } from "react-aad-msal/dist/typings/interfaces";
import { Link } from "react-router-dom";
// import { CustomButton } from "../../components/CustomButton";
import { Alert } from "reactstrap";
import { CheckSquareIcon } from "../../components/icons/CheckSquareIcon";
import { fetchUserVote, UserVote } from "../../services/api";
import "./index.css";

type Props = {
  className?: string;
  account: IAccountInfo;
};

export function WellnessChallengeMainContent({ className, account }: Props) {
  const [selectedItem, setSelectedItem] = useState<string>();
  const [votedItem, setVotedItem] = useState<string>();
  // const [isVoting, setIsvoting] = useState<boolean>(false);
  const [userVote, setUserVote] = useState<UserVote>();
  const [error, setError] = useState<string>();

  const loadVote = useCallback(async () => {
    try {
      const data = await fetchUserVote(account.jwtIdToken);
      setUserVote(data);
      setVotedItem(data.userVote?.organization);
      setSelectedItem(data.userVote?.organization);
    } catch (e) {
      setError(e.toString());
    }
  }, [account.jwtIdToken]);

  useEffect(() => {
    loadVote();
  }, [loadVote]);

  // const select = (item: string) => {
  //   if (!isVoting) {
  //     setSelectedItem(item);
  //   }
  // };

  // const vote = async (item: string) => {
  //   setIsvoting(true);
  //   setError(undefined);
  //   try {
  //     await postVote(item, account.jwtIdToken);
  //     setVotedItem(item);
  //     await loadVote();
  //   } catch (e) {
  //     setError(e.message);
  //   } finally {
  //     setIsvoting(false);
  //   }
  // };

  return (
    <div
      className={classnames(
        "WellnessChallengeMainContent__container",
        className
      )}
    >
      {error && <Alert color="danger">{error}</Alert>}
      <div className="WellnessChallenge__before_title mb-3">
        21 DAY WELLNESS CHALLENGE
      </div>
      <div className="WellnessChallenge__title mb-4">
        We’ve raised €250,000 🎉
      </div>
      <div className="embed-responsive embed-responsive-16by9 mb-5">
        <iframe
          className="embed-responsive-item"
          src="https://player.vimeo.com/video/505139398"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="About video presentation"
        />
      </div>
      <div className="WellnessChallengeMainContent__description mt-5">
        It’s time to decide how this incredible donation will be distributed
        among our five charity partners. The more votes each receives, the
        bigger percentage of the pot they get. For more information, head over
        to <Link to="/about">About</Link> and{" "}
        <Link to="/charities">Charity Partners</Link> pages. Vote for your
        favorite charity below.
      </div>
      <div className="WellnessChallengeMainContent__select_charity mt-5">
        <ul
          className={classnames({
            "WellnessChallengeMainContent__select_charity--has-selected":
              selectedItem !== undefined,
          })}
        >
          {[
            "WWF",
            "Save The Children",
            "Red Cross",
            "Fashion Minority Alliance",
            "ILGA",
          ].map((item) => (
            <li key={item} className="d-lg-flex align-items-center">
              <div
                className={classnames(
                  "WellnessChallengeMainContent__select_charity_button_wrapper",
                  "mb-2",
                  "mb-md-0",
                  "d-flex",
                  "align-items-center",
                  {
                    "WellnessChallengeMainContent__select_charity_button_wrapper--selected":
                      selectedItem === item,
                  }
                )}
              >
                <button
                  // onClick={() => select(item)}
                  className="WellnessChallengeMainContent__select_charity_button"
                  disabled
                >
                  {item}
                </button>
                {
                  userVote?.organizationsPartial &&
                  userVote.total && (
                    <div className="WellnessChallengeMainContent__percentage">
                      {userVote.organizationsPartial[item]
                        ? Math.floor(
                            (userVote.organizationsPartial[item] * 100) /
                              userVote.total
                          )
                        : 0}
                      %
                    </div>
                  )}
              </div>
              <div className="ml-md-3">
                {/*{item === selectedItem && !isVoting && item !== votedItem && (*/}
                {/*  <CustomButton*/}
                {/*    onClick={() => vote(item)}*/}
                {/*    className="WellnessChallengeMainContent__charity_confirm_button"*/}
                {/*  >*/}
                {/*    {votedItem ? "Change vote" : "Confirm"}*/}
                {/*  </CustomButton>*/}
                {/*)}*/}
                {/*{item === selectedItem && isVoting && (*/}
                {/*  <Spinner className="WellnessChallenge__orange_elements" />*/}
                {/*)}*/}
                {item === votedItem && (
                  <div className="WellnessChallenge__orange_elements">
                    <CheckSquareIcon />
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
      {votedItem && <div>Thanks for your vote!</div>}
    </div>
  );
}
